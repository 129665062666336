import { Box, type BoxProps } from "@mantine/core";
import { useHover, useIsFirstRender } from "@mantine/hooks";
import React, { useEffect, useState } from "react";
import type { BentoCardChildrenProps } from "src/utils/colorScheme/palette";
import type { SavedFolder } from "../savedModal/ModalSaved";
import { InspoCardOverlay } from "../ui/InspoCardOverlay";

// Horizontal

export function BentoHorizontalLg({
  children,
}: {
  children?: React.ReactNode;
}) {
  return (
    <Box
      style={{
        gridColumn: "span 4",
        gridRow: "span 2",
      }}
    >
      <BentoCard {...(React.isValidElement(children) ? children.props : {})}>
        {children ?? "Horizontal Lg"}
      </BentoCard>
    </Box>
  );
}

export function BentoHorizontalMd({
  children,
}: {
  children?: React.ReactNode;
}) {
  return (
    <Box
      style={{
        gridColumn: "span 2",
        gridRow: "span 1",
      }}
    >
      <BentoCard {...(React.isValidElement(children) ? children.props : {})}>
        {children ?? "Horizontal Md"}
      </BentoCard>
    </Box>
  );
}

// Square

export function BentoSquareLg({ children }: { children?: React.ReactNode }) {
  return (
    <Box
      style={{
        gridColumn: "span 2",
        gridRow: "span 2",
      }}
    >
      <BentoCard {...(React.isValidElement(children) ? children.props : {})}>
        {children ?? "Big Square"}
      </BentoCard>
    </Box>
  );
}

export function BentoSquareMd({ children }: { children?: React.ReactNode }) {
  return (
    <Box
      style={{
        gridColumn: "span 1",
        gridRow: "span 1",
      }}
    >
      <BentoCard {...(React.isValidElement(children) ? children.props : {})}>
        {children ?? "Small Square"}
      </BentoCard>
    </Box>
  );
}

// Card Container

function BentoCard({ children }: { children?: React.ReactNode }) {
  const isFirstRender = useIsFirstRender();
  const { hovered, ref } = useHover();

  // @ts-ignore
  const childProps = React.Children.only(children)?.props;
  const imgs = childProps?.content?.imgs;
  const {
    cardState,
    forceShowUploadImage,
  }: {
    cardState: BentoCardChildrenProps;
    folders: SavedFolder[];
    forceShowUploadImage?: boolean;
  } = childProps;

  // local state
  const [imgIndex, setImgIndex] = useState(cardState?.imgIndex ?? undefined);
  const [imgLocked, setImgLocked] = useState(cardState?.imgLocked ?? false);
  const [uploadedImagePath, setUploadedImagePath] = useState<string | null>(
    cardState?.uploadedImagePath ?? null,
  );

  useEffect(() => {
    if (cardState) {
      setImgIndex(cardState?.imgIndex ?? undefined);
      setImgLocked(cardState?.imgLocked ?? false);
      setUploadedImagePath(cardState?.uploadedImagePath ?? null);
    }
  }, [cardState]);

  useEffect(
    function setRandomImage() {
      if (
        imgs !== undefined &&
        imgs.length > 0 &&
        imgIndex === undefined &&
        !uploadedImagePath
      ) {
        const randomIndex = Math.floor(Math.random() * imgs.length);
        updateLocalStateWhenPropChanges({
          newImgIndex: randomIndex,
          newImgLocked: false,
          newUploadedImagePath: null,
        });
      }
    },
    [imgs, imgIndex, uploadedImagePath],
  );

  function updateLocalStateWhenPropChanges({
    newImgIndex,
    newImgLocked,
    newUploadedImagePath,
  }: {
    newImgIndex: number | undefined;
    newImgLocked: boolean;
    newUploadedImagePath: string | null;
  }) {
    let shouldUpdateCardState = false;

    if ((newImgIndex && newImgIndex !== imgIndex) || isFirstRender) {
      setImgIndex(newImgIndex);
      shouldUpdateCardState = true;
    }

    if (newImgLocked !== imgLocked || isFirstRender) {
      setImgLocked(newImgLocked ?? false);
      shouldUpdateCardState = true;
    }

    if (newUploadedImagePath !== uploadedImagePath || isFirstRender) {
      setUploadedImagePath(newUploadedImagePath ?? null);
      shouldUpdateCardState = true;
    }

    if (shouldUpdateCardState) {
      childProps.setCardState?.({
        imgIndex: newImgIndex,
        imgLocked: newImgLocked,
        uploadedImagePath: newUploadedImagePath,
      });
    }
  }

  const shouldShowCard =
    imgs === undefined ||
    (imgs.length > 0 && imgIndex !== undefined) ||
    uploadedImagePath !== null;

  const getCustomImageUrl = (path: string | null) => {
    if (!path) return null;
    return `https://jqwmwvdxmcktrzcbkxcl.supabase.co/storage/v1/object/public/user-uploads/${path}`;
  };

  return (
    <Box
      ref={ref}
      h={"100%"}
      w={"100%"}
      pos={"relative"}
      style={{
        borderRadius: "var(--mantine-radius-xs)",
        overflow: "hidden",
      }}
    >
      {shouldShowCard && (
        <>
          {children
            ? // @ts-ignore
              React.cloneElement(children, {
                imgIndex: imgIndex ?? undefined,
                uploadedImagePath: getCustomImageUrl(uploadedImagePath),
              })
            : "Bento Card"}
        </>
      )}

      <InspoCardOverlay
        show={hovered}
        forceShowUploadImage={forceShowUploadImage}
        imgs={imgs ?? []}
        currentImageIndex={imgIndex ?? 0}
        setCurrentImgIndex={(imgIndex) => {
          updateLocalStateWhenPropChanges({
            newImgIndex: imgIndex,
            newImgLocked: imgLocked,
            newUploadedImagePath: null,
          });
        }}
        imgLocked={imgLocked}
        setImgLocked={(imgLocked) => {
          updateLocalStateWhenPropChanges({
            newImgIndex: imgIndex,
            newImgLocked: imgLocked,
            newUploadedImagePath: imgLocked ? uploadedImagePath : null, // Clear uploaded image when unlocking,
          });
        }}
        uploadedImagePath={uploadedImagePath}
        setUploadedImagePath={(path) => {
          updateLocalStateWhenPropChanges({
            newImgLocked: path !== null,
            newUploadedImagePath: path,
            newImgIndex: imgIndex,
          });
        }}
        getFullImageUrl={getCustomImageUrl}
      />
    </Box>
  );
}

// Bento Container

export function BentoContainer({
  children,
  style,
  ...props
}: { children?: React.ReactNode } & BoxProps) {
  return (
    <Box
      id="bento-container"
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(8, calc(100% / 8))",
        gridTemplateRows: "repeat(4, 25%)",
        gridGap: "var(--mantine-spacing-xs)",
        ...style,
      }}
      {...props}
    >
      {children}
    </Box>
  );
}

export function BentoContainerPlayground({
  children,
  style,
  ...props
}: { children?: React.ReactNode } & BoxProps) {
  return (
    <Box
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(8, calc(100% / 8))",
        gridTemplateRows: "repeat(2, 50%)",
        height: "calc((100vh - 300px) / 2)",
        backgroundColor: "var(--mantine-color-gray-4)",
        ...style,
      }}
      {...props}
    >
      {children}
    </Box>
  );
}
