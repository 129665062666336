import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
  type ReactNode,
} from "react";
import {
  fetchMoodboards,
  type SavedFolder,
} from "../components/savedModal/ModalSaved";

interface FoldersContextType {
  folders: SavedFolder[];
  refetchFolders: () => void;
}

const FoldersContext = createContext<FoldersContextType | undefined>(undefined);

export function FoldersProvider({ children }: { children: ReactNode }) {
  const [folders, setFolders] = useState<SavedFolder[]>([]);

  const refetchFolders = useCallback(async () => {
    const fetchedFolders = await fetchMoodboards();
    setFolders(fetchedFolders);
  }, []);

  useEffect(() => {
    refetchFolders();
  }, []);

  return (
    <FoldersContext.Provider value={{ folders, refetchFolders }}>
      {children}
    </FoldersContext.Provider>
  );
}

export function useFolders() {
  const context = useContext(FoldersContext);
  if (context === undefined) {
    throw new Error("useFolders must be used within a FoldersProvider");
  }
  return context;
}
