import {
  Icon123,
  IconBaguette,
  IconCoffee,
  IconCookie,
  IconGlass,
  IconGlassChampagne,
  IconGrillFork,
} from "@tabler/icons-react";
import type { InspoTypeCardProps } from "src/utils/colorScheme/palette";
import { Horizontal } from "../mantine-layout-components/Horizontal";
import type { BentoCardChildrenProps } from "src/utils/colorScheme/palette";
import React from "react";
import { Vertical } from "../mantine-layout-components/Vertical";
import { Box, Divider, Text, Title } from "@mantine/core";

type CartContentProps = {
  title: string;
  items: {
    type: "price" | "deliverytime";
    title: string;
    value: string;
  }[];
  button: string;
};

export const cartContent = {
  cart1: {
    title:
      "Made in Italy from recycled steel, assembled by hand and then powder coated",
    items: [
      { type: "price", title: "Price", value: "€570,00" },
      { type: "deliverytime", title: "Delivery time", value: "3 - 5 days" },
    ],
    button: "Shop now",
  },
} as const satisfies Record<string, CartContentProps>;

export default function Cart({
  colorScheme,
  content,
  ...props
}: InspoTypeCardProps<CartContentProps> & BentoCardChildrenProps) {
  if (!content) return null;

  return (
    <Vertical
      fullW
      fullH
      bg={colorScheme.background}
      gap={"md"}
      centerH
      px={"sm"}
      {...props}
    >
      <Text variant="card-paragraph-lg" c={colorScheme.textdark} ta={"start"}>
        {content.title}
      </Text>

      <Vertical fullW gap={"sm"}>
        {content.items.map((el, i) => (
          <React.Fragment key={i}>
            <Divider w={"100%"} color={colorScheme.textdark} opacity={0.5} />

            <Horizontal fullW spaceBetween centerV>
              <Text variant={"card-paragraph-md"} c={colorScheme.textdark}>
                {el.title}
              </Text>

              <Text variant="card-paragraph-md" c={colorScheme.textdark}>
                {el.value}
              </Text>
            </Horizontal>
          </React.Fragment>
        ))}
      </Vertical>

      <Text
        w={"100%"}
        bg={colorScheme.ctadark}
        c={colorScheme.textlight}
        py={"sm"}
        px={"sm"}
        ta={"center"}
        variant="card-paragraph-lg"
        style={{ borderRadius: "var(--mantine-radius-xs)" }}
      >
        {content.button}
      </Text>
    </Vertical>
  );
}
