import Authors, { authorsContent } from "../components/bentoCards/Authors";
import {
  BentoHorizontalLg,
  BentoHorizontalMd,
  BentoSquareLg,
  BentoSquareMd,
} from "../components/bentoCards/BentoCards";
import BgPhoto, { bgPhotoContent } from "../components/bentoCards/BgPhoto";
import BlogArticleWithImageLeft, {
  blogArticleWithImageLeftContent,
} from "../components/bentoCards/BlogArticleWithImageLeft";
import BlogArticleWithImageLeftAndTagTop, {
  blogArticleWithImageLeftAndTagTopContent,
} from "../components/bentoCards/BlogArticleWithImageLeftAndTagTop";
import BlogArticleWithImageRight, {
  blogArticleWithImageRightContent,
} from "../components/bentoCards/BlogArticleWithImageRight";
import BlogArticleWithImageTop, {
  blogArticleWithImageTopContent,
} from "../components/bentoCards/BlogArticleWithImageTop";
import Cart, { cartContent } from "../components/bentoCards/Cart";
import EcommItem, {
  ecommItemContent,
} from "../components/bentoCards/EcommItem";
import HeadingWithBgColor, {
  headingWithBgColorContent,
} from "../components/bentoCards/HeadingWithBgColor";
import HeadingWithBgPhoto, {
  headingWithBgPhotoContent,
} from "../components/bentoCards/HeadingWithBgPhoto";
import IconsGrid, {
  iconsGridContent,
} from "../components/bentoCards/IconsGrid";
import MenuPdfs, { menuPdfsContent } from "../components/bentoCards/MenuPdfs";
import ProductSingle, {
  productSingleContent,
} from "../components/bentoCards/ProductSingle";
import Savings, { savingsContent } from "../components/bentoCards/Savings";
import TagsGrid, { tagsGridContent } from "../components/bentoCards/TagsGrid";
import TextLinesWithUpdatesTag, {
  textLinesWithUpdatesTagContent,
} from "../components/bentoCards/TextLinesWithUpdatesTag";
import TitleWithBgColor, {
  titleWithBgColorContent,
} from "../components/bentoCards/TitleWithBgColor";
import Unicorns, { unicornsContent } from "../components/bentoCards/Unicorns";
import { useColorPalette } from "../contexts/ColorSchemeContext";

export function useBentoCards() {
  const { currentColorScheme: colorScheme } = useColorPalette();

  const bentoCards = {
    ecommerce: [
      <BentoHorizontalLg
        children={
          <EcommItem
            key={"a1b2c3d4e5"}
            colorScheme={colorScheme.light}
            content={ecommItemContent.ecomm1}
          />
        }
      />,
      <BentoSquareLg
        children={
          <Cart
            key={"f6g7h8i9j0"}
            colorScheme={colorScheme.bright}
            content={cartContent.cart1}
          />
        }
      />,
      <BentoHorizontalMd
        children={
          <TitleWithBgColor
            key={"k1l2m3n4o5"}
            colorScheme={colorScheme.dark}
            content={titleWithBgColorContent.title1}
            // @ts-ignore
            forceShowUploadImage={true}
          />
        }
      />,
      <BentoHorizontalMd
        children={
          <BgPhoto
            key={"p6q7r8s9t0"}
            colorScheme={colorScheme.light}
            content={bgPhotoContent.fashionLandscape}
          />
        }
      />,
      <BentoSquareLg
        children={
          <TextLinesWithUpdatesTag
            key={"u1v2w3x4y5"}
            colorScheme={colorScheme.dark}
            content={textLinesWithUpdatesTagContent.list1}
          />
        }
      />,
      <BentoSquareMd
        children={
          <IconsGrid
            key={"z6a7b8c9d0"}
            colorScheme={colorScheme.bright}
            content={iconsGridContent.iconFashion}
          />
        }
      />,
      <BentoSquareMd
        children={
          <BgPhoto
            key={"e1f2g3h4i5"}
            colorScheme={colorScheme.light}
            content={bgPhotoContent.fashionSquare}
          />
        }
      />,
      <BentoHorizontalLg
        children={
          <HeadingWithBgPhoto
            key={"j6k7l8m9n0"}
            colorScheme={colorScheme.light}
            content={headingWithBgPhotoContent.heading4}
          />
        }
      />,
      <BentoHorizontalMd
        children={
          <BgPhoto
            key={"o1p2q3r4s5"}
            colorScheme={colorScheme.light}
            content={bgPhotoContent.fashionLandscape}
          />
        }
      />,
    ],
    editorial: [
      <BentoHorizontalMd
        children={
          <TitleWithBgColor
            key={"a1b2c3d4e5"}
            colorScheme={colorScheme.bright}
            content={titleWithBgColorContent.title2}
            // @ts-ignore
            forceShowUploadImage={true}
          />
        }
      />,
      <BentoSquareLg
        children={
          <Authors
            key={"f6g7h8i9j0"}
            colorScheme={colorScheme.dark}
            content={authorsContent.authors1}
          />
        }
      />,
      <BentoHorizontalLg
        children={
          <BlogArticleWithImageLeftAndTagTop
            key={"k1l2m3n4o5"}
            colorScheme={colorScheme.light}
            content={blogArticleWithImageLeftAndTagTopContent.food1}
          />
        }
      />,
      <BentoHorizontalMd
        children={
          <BgPhoto
            key={"p6q7r8s9t0"}
            colorScheme={colorScheme.light}
            content={bgPhotoContent.editorialLandscape}
          />
        }
      />,
      <BentoHorizontalLg
        children={
          <HeadingWithBgPhoto
            key={"u1v2w3x4y5"}
            colorScheme={colorScheme.light}
            content={headingWithBgPhotoContent.heading2}
          />
        }
      />,
      <BentoSquareLg
        children={
          <TagsGrid
            key={"z6a7b8c9d0"}
            colorScheme={colorScheme.bright}
            content={tagsGridContent.tags1}
          />
        }
      />,
      <BentoSquareMd
        children={
          <BgPhoto
            key={"e1f2g3h4i5"}
            colorScheme={colorScheme.light}
            content={bgPhotoContent.editorialSquare}
          />
        }
      />,
      <BentoSquareMd
        children={
          <IconsGrid
            key={"j6k7l8m9n0"}
            colorScheme={colorScheme.dark}
            content={iconsGridContent.iconEditorial}
          />
        }
      />,
      <BentoHorizontalMd
        children={
          <BgPhoto
            key={"o1p2q3r4s5"}
            colorScheme={colorScheme.light}
            content={bgPhotoContent.editorialLandscape}
          />
        }
      />,
    ],
    finance: [
      <BentoHorizontalMd
        children={
          <TitleWithBgColor
            key={"t6u7v8w9x0"}
            colorScheme={colorScheme.dark}
            content={titleWithBgColorContent.title3}
            // @ts-ignore
            forceShowUploadImage={true}
          />
        }
      />,
      <BentoSquareLg
        children={
          <Unicorns
            key={"y1z2a3b4c5"}
            colorScheme={colorScheme.bright}
            content={unicornsContent.unicorns1}
          />
        }
      />,
      <BentoHorizontalLg
        children={
          <HeadingWithBgPhoto
            key={"d6e7f8g9h0"}
            colorScheme={colorScheme.light}
            content={headingWithBgPhotoContent.heading1}
          />
        }
      />,
      <BentoHorizontalMd
        children={
          <BgPhoto
            key={"i1j2k3l4m5"}
            colorScheme={colorScheme.light}
            content={bgPhotoContent.financeLandscape}
          />
        }
      />,
      <BentoHorizontalLg
        children={
          <BlogArticleWithImageRight
            key={"n6o7p8q9r0"}
            colorScheme={colorScheme.light}
            content={blogArticleWithImageRightContent.article1}
          />
        }
      />,
      <BentoSquareMd
        children={
          <BgPhoto
            key={"s1t2u3v4w5"}
            colorScheme={colorScheme.light}
            content={bgPhotoContent.financeSquare}
          />
        }
      />,
      <BentoSquareMd
        children={
          <IconsGrid
            key={"x6y7z8a9b0"}
            colorScheme={colorScheme.dark}
            content={iconsGridContent.iconFinance}
          />
        }
      />,
      <BentoSquareLg
        children={
          <Savings
            key={"c1d2e3f4g5"}
            colorScheme={colorScheme.bright}
            content={savingsContent.saving1}
          />
        }
      />,
      <BentoHorizontalMd
        children={
          <BgPhoto
            key={"h6i7j8k9l0"}
            colorScheme={colorScheme.light}
            content={bgPhotoContent.financeLandscape}
          />
        }
      />,
    ],
    foodNDrink: [
      <BentoSquareLg
        children={
          <BlogArticleWithImageTop
            key={"334ba411"}
            colorScheme={colorScheme.light}
            content={blogArticleWithImageTopContent.blog1}
          />
        }
      />,
      <BentoHorizontalMd
        children={
          <TitleWithBgColor
            key={"e022d8b3"}
            colorScheme={colorScheme.dark}
            content={titleWithBgColorContent.title4}
            // @ts-ignore
            forceShowUploadImage={true}
          />
        }
      />,
      <BentoHorizontalLg
        children={
          <BlogArticleWithImageLeft
            key={"ccd57935"}
            colorScheme={colorScheme.bright}
            content={blogArticleWithImageLeftContent.article1}
          />
        }
      />,
      <BentoHorizontalMd
        children={
          <BgPhoto
            key={"3f7364ca"}
            colorScheme={colorScheme.light}
            content={bgPhotoContent.foodLandscape}
          />
        }
      />,
      <BentoHorizontalLg
        children={
          <HeadingWithBgPhoto
            key={"48a9"}
            colorScheme={colorScheme.light}
            content={headingWithBgPhotoContent.heading3}
          />
        }
      />,
      <BentoSquareLg
        children={
          <MenuPdfs
            key={"4511"}
            colorScheme={colorScheme.dark}
            content={menuPdfsContent.menu1}
          />
        }
      />,
      <BentoHorizontalMd
        children={
          <HeadingWithBgColor
            key={"b6ba"}
            colorScheme={colorScheme.light}
            content={headingWithBgColorContent.heading1}
          />
        }
      />,
      <BentoSquareMd
        children={
          <IconsGrid
            key={"837e4801a790"}
            colorScheme={colorScheme.bright}
            content={iconsGridContent.iconFood}
          />
        }
      />,
      <BentoSquareMd
        children={
          <BgPhoto
            key={"c651f984"}
            colorScheme={colorScheme.light}
            content={bgPhotoContent.foodSquare}
          />
        }
      />,
    ],
    sport: [
      <BentoHorizontalMd
        children={
          <BgPhoto
            key={"e4852c84"}
            colorScheme={colorScheme.light}
            content={bgPhotoContent.sportLandscape}
          />
        }
      />,
      <BentoSquareLg
        children={
          <Authors
            key={"7255e263"}
            colorScheme={colorScheme.dark}
            content={authorsContent.authors2}
          />
        }
      />,
      <BentoHorizontalLg
        children={
          <HeadingWithBgPhoto
            key={"f588a7ea"}
            colorScheme={colorScheme.light}
            content={headingWithBgPhotoContent.heading5}
          />
        }
      />,
      <BentoHorizontalMd
        children={
          <TitleWithBgColor
            key={"6f615f18"}
            colorScheme={colorScheme.bright}
            content={titleWithBgColorContent.title5}
            // @ts-ignore
            forceShowUploadImage={true}
          />
        }
      />,
      <BentoHorizontalLg
        children={
          <BlogArticleWithImageLeft
            key={"0b1bf232"}
            colorScheme={colorScheme.light}
            content={blogArticleWithImageLeftContent.article2}
          />
        }
      />,
      <BentoHorizontalMd
        children={
          <BgPhoto
            key={"9a83dd9s"}
            colorScheme={colorScheme.light}
            content={bgPhotoContent.sportLandscape}
          />
        }
      />,
      <BentoSquareLg
        children={
          <BlogArticleWithImageTop
            key={"3303af1b"}
            colorScheme={colorScheme.dark}
            content={blogArticleWithImageTopContent.blog2}
          />
        }
      />,
      <BentoSquareMd
        children={
          <IconsGrid
            key={"3e8eeaca"}
            colorScheme={colorScheme.bright}
            content={iconsGridContent.iconSport}
          />
        }
      />,
      <BentoSquareMd
        children={
          <BgPhoto
            key={"15a2f581"}
            colorScheme={colorScheme.light}
            content={bgPhotoContent.sportSquare}
          />
        }
      />,
    ],

    beauty: [
      <BentoHorizontalLg
        children={
          <BlogArticleWithImageLeft
            key={"bcb7fa7d"}
            colorScheme={colorScheme.bright}
            content={blogArticleWithImageLeftContent.article3}
          />
        }
      />,
      <BentoHorizontalMd
        children={
          <TitleWithBgColor
            key={"ffc62b6b"}
            colorScheme={colorScheme.dark}
            content={titleWithBgColorContent.title6}
            // @ts-ignore
            forceShowUploadImage={true}
          />
        }
      />,
      <BentoSquareLg
        children={
          <ProductSingle
            key={"e8c1ac6da873"}
            colorScheme={colorScheme.light}
            content={productSingleContent.product1}
          />
        }
      />,
      <BentoHorizontalMd
        children={
          <BgPhoto
            key={"10c7972a"}
            colorScheme={colorScheme.light}
            content={bgPhotoContent.beautyLandscape}
          />
        }
      />,
      <BentoHorizontalMd
        children={
          <HeadingWithBgColor
            key={"80e696ca"}
            colorScheme={colorScheme.light}
            content={headingWithBgColorContent.heading2}
          />
        }
      />,
      <BentoSquareLg
        children={
          <MenuPdfs
            key={"45eb"}
            colorScheme={colorScheme.dark}
            content={menuPdfsContent.menu2}
          />
        }
      />,
      <BentoHorizontalLg
        children={
          <HeadingWithBgPhoto
            key={"c8bbaabbfdd3"}
            colorScheme={colorScheme.light}
            content={headingWithBgPhotoContent.heading6}
          />
        }
      />,

      <BentoSquareMd
        children={
          <IconsGrid
            key={"c6296262"}
            colorScheme={colorScheme.bright}
            content={iconsGridContent.iconBeauty}
          />
        }
      />,
      <BentoSquareMd
        children={
          <BgPhoto
            key={"662cf277"}
            colorScheme={colorScheme.light}
            content={bgPhotoContent.beautySquare}
          />
        }
      />,
    ],
  };

  return bentoCards;
}
