import { Vertical } from "../mantine-layout-components/Vertical";
import { Horizontal } from "../mantine-layout-components/Horizontal";
import {
  ActionIcon,
  Button,
  Divider,
  Image,
  Text,
  UnstyledButton,
} from "@mantine/core";
import { IconArrowLeft, IconCheck, IconCopy, IconX } from "@tabler/icons-react";
import { InspoConfirmDeleteButton } from "../ui/InspoConfirmDeleteButton";
import { ScrollArea } from "@mantine/core";
import { Box } from "@mantine/core";
import TagsGrid, { tagsGridContent } from "../bentoCards/TagsGrid";
import { colorSchemes } from "src/utils/colorScheme/palette";
import TextLinesWithUpdatesTag, {
  textLinesWithUpdatesTagContent,
} from "../bentoCards/TextLinesWithUpdatesTag";
import type { SavedFolder } from "./ModalSaved";
import { fontPairs } from "src/utils/typography/configs/fontPairs";
import { industries } from "../../utils";
import MenuPdfs, { menuPdfsContent } from "../bentoCards/MenuPdfs";
import Savings, { savingsContent } from "../bentoCards/Savings";
import Authors, { authorsContent } from "../bentoCards/Authors";
import { cloudinaryUrl } from "src/utils/images/cloudinaryImages";
import { useClipboard } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";

function parseMoodboardData(moodboardUrl: string) {
  const searchParams = new URLSearchParams(new URL(moodboardUrl).search);
  const industryParam = searchParams.get("industry");
  const fontsParam = searchParams.get("fonts");
  const paletteParam = searchParams.get("palette");
  const cardsStateParam = searchParams.get("cardsState");
  const titleIsLockedAtFont = searchParams.get("titleIsLockedAtFont");
  const bodyIsLockedAtFont = searchParams.get("bodyIsLockedAtFont");
  const customColorScheme = searchParams.get("customColorScheme")
    ? JSON.parse(searchParams.get("customColorScheme") || "{}")
    : null;

  const industry = industries.find(
    (industry) => industry.slug === industryParam,
  );
  const fontPair = fontPairs.find((pair) => pair.slug === fontsParam);
  const cardState = JSON.parse(cardsStateParam || "{}");

  const selectedImages = Object.values(cardState)
    .filter((el: any) => el.imgIndex !== 0 && el.imgCloudinaryId)
    .map((el: any) => el.imgCloudinaryId)
    .slice(0, 3)
    .map((el: string) => cloudinaryUrl(el));

  return {
    industryLabel: industry?.label,
    industry: industry?.slug,
    title: titleIsLockedAtFont || fontPair?.title["readable-font-family"],
    titleFontFamily: titleIsLockedAtFont ?? fontPair?.title["font-family"],
    body: bodyIsLockedAtFont || fontPair?.body["readable-font-family"],
    bodyFontFamily: bodyIsLockedAtFont ?? fontPair?.body["font-family"],
    colorPalette: customColorScheme
      ? customColorScheme
      : colorSchemes.find((scheme) => scheme.slug === paletteParam),
    imgs: selectedImages,
    url: moodboardUrl,
  };
}

export function FolderContent({
  folder,
  handleBack,
  handleClose,
  handleUpdateMoodboard,
}: {
  folder: SavedFolder;
  handleBack: () => void;
  handleClose: () => void;
  handleUpdateMoodboard: (
    folderId: string,
    moodboards: string[],
  ) => Promise<void>;
}) {
  const { copy } = useClipboard();
  const isEmpty = folder.moodboards.length === 0;

  const data = folder.moodboards.map((moodboard) =>
    parseMoodboardData(moodboard),
  );

  return (
    <Vertical fullW gap="md">
      <Horizontal fullW align="center" mb="md" style={{ position: "relative" }}>
        <ActionIcon
          variant="subtle"
          size={48}
          onClick={handleBack}
          style={{ position: "absolute", left: 0 }}
        >
          <IconArrowLeft size={24} />
        </ActionIcon>
        <Text variant="paragraph-lg" style={{ margin: "0 auto" }}>
          {folder.name}
        </Text>
        <Horizontal gap="xs" style={{ position: "absolute", right: 0 }}>
          <ActionIcon variant="subtle" size={48} onClick={handleClose}>
            <IconX size={24} />
          </ActionIcon>
        </Horizontal>
      </Horizontal>

      {isEmpty && (
        <Vertical fullW center>
          <Text variant="paragraph-xl" ta="center" c="dimmed" mt="md">
            This folder is empty.
          </Text>
          <Text variant="paragraph-md" ta="center" c="dimmed">
            Save some moodboards to see them here.
          </Text>
        </Vertical>
      )}

      {!isEmpty && (
        <ScrollArea h={400} w={"100%"}>
          <Vertical fullW gap="xs">
            {data.map((item, index) => (
              <Horizontal
                key={index}
                fullW
                fullH
                bg={"white"}
                gap={0}
                style={{
                  borderRadius: "var(--mantine-radius-sm)",
                  cursor: "pointer",
                }}
                noWrap
              >
                <Horizontal
                  flex={1}
                  bg={"white"}
                  gap={0}
                  onClick={() => {
                    window.location.href = item.url;
                  }}
                >
                  <Box
                    w={110}
                    h={120}
                    style={{ overflow: "hidden" }}
                    mah={"120px"}
                  >
                    {item.industry === "foodNDrink" && (
                      <MenuPdfs
                        key={"4511"}
                        colorScheme={item.colorPalette.dark}
                        content={menuPdfsContent.menu1}
                        titleFontFamily={item.titleFontFamily}
                        bodyFontFamily={item.bodyFontFamily}
                        style={{
                          zoom: 0.4,
                          borderTopLeftRadius: "var(--mantine-radius-sm)",
                          borderBottomLeftRadius: "var(--mantine-radius-sm)",
                        }}
                        w={"100%"}
                      />
                    )}
                    {item.industry === "ecommerce" && (
                      <TextLinesWithUpdatesTag
                        key={"u1v2w3x4y5"}
                        colorScheme={colorSchemes[0].dark}
                        content={textLinesWithUpdatesTagContent.list1}
                        titleFontFamily={item.titleFontFamily}
                        bodyFontFamily={item.bodyFontFamily}
                        w={"100%"}
                        h={"100%"}
                        style={{
                          zoom: 0.4,
                          borderTopLeftRadius: "var(--mantine-radius-sm)",
                          borderBottomLeftRadius: "var(--mantine-radius-sm)",
                        }}
                      />
                    )}

                    {item.industry === "beauty" && (
                      <MenuPdfs
                        key={"45eb"}
                        titleFontFamily={item.titleFontFamily}
                        bodyFontFamily={item.bodyFontFamily}
                        colorScheme={item.colorPalette.dark}
                        content={menuPdfsContent.menu2}
                        style={{
                          zoom: 0.4,
                          borderTopLeftRadius: "var(--mantine-radius-sm)",
                          borderBottomLeftRadius: "var(--mantine-radius-sm)",
                        }}
                        w={"100%"}
                      />
                    )}

                    {item.industry === "editorial" && (
                      <TagsGrid
                        key={"z6a7b8c9d0"}
                        titleFontFamily={item.titleFontFamily}
                        bodyFontFamily={item.bodyFontFamily}
                        colorScheme={item.colorPalette.bright}
                        content={tagsGridContent.tags1}
                        style={{
                          zoom: 0.4,
                          borderTopLeftRadius: "var(--mantine-radius-sm)",
                          borderBottomLeftRadius: "var(--mantine-radius-sm)",
                        }}
                        w={"100%"}
                      />
                    )}

                    {item.industry === "finance" && (
                      <Savings
                        key={"c1d2e3f4g5"}
                        colorScheme={item.colorPalette.bright}
                        content={savingsContent.saving1}
                        style={{
                          zoom: 0.4,
                          borderTopLeftRadius: "var(--mantine-radius-sm)",
                          borderBottomLeftRadius: "var(--mantine-radius-sm)",
                        }}
                        w={"100%"}
                      />
                    )}

                    {item.industry === "sport" && (
                      <Authors
                        key={"7255e263"}
                        titleFontFamily={item.titleFontFamily}
                        bodyFontFamily={item.bodyFontFamily}
                        colorScheme={item.colorPalette.dark}
                        content={authorsContent.authors2}
                        style={{
                          zoom: 0.4,
                          borderTopLeftRadius: "var(--mantine-radius-sm)",
                          borderBottomLeftRadius: "var(--mantine-radius-sm)",
                        }}
                        w={"100%"}
                      />
                    )}
                  </Box>

                  <Vertical gap="0" w={40} fullH spaceBetween>
                    {item.imgs.map((img, index) => (
                      <Box>
                        <Image
                          key={index}
                          src={img}
                          fit="cover"
                          h={40}
                          w={40}
                        />
                      </Box>
                    ))}
                  </Vertical>

                  <Vertical gap="0" ml="sm" flex={1}>
                    <Text variant="label-md" c="dimmed">
                      {item.industryLabel}
                    </Text>
                    <Text mt="xxs" variant="paragraph-lg">
                      {item.title}
                    </Text>
                    <Text variant="paragraph-lg">{item.body}</Text>

                    <Horizontal
                      gap={0}
                      mt={"xxs"}
                      style={{
                        borderRadius: "var(--mantine-radius-xxs)",
                        borderWidth: "1px",
                        borderStyle: "solid",
                        borderColor: "#B5B5B5",
                      }}
                    >
                      {[
                        item.colorPalette.dark["background"],
                        item.colorPalette.light["background"],
                        item.colorPalette.bright["background"],
                      ].map((c) => {
                        return <Box key={c} bg={c} w={20} h={20} />;
                      })}
                    </Horizontal>
                  </Vertical>
                </Horizontal>

                <Divider
                  color={"var(--mantine-color-gray-3)"}
                  orientation="vertical"
                />

                <Vertical fullH gap={0} mx={"xs"} spaceBetween>
                  <InspoConfirmDeleteButton
                    variant="transparent"
                    onDelete={() => {
                      handleUpdateMoodboard(
                        folder.id,
                        folder.moodboards.filter((_, i) => i !== index),
                      );
                    }}
                  />

                  <Divider
                    w={"100%"}
                    orientation="horizontal"
                    color={"var(--mantine-color-gray-3)"}
                    my={12}
                  />

                  <Button
                    variant="transparent"
                    fullWidth
                    leftSection={<IconCopy size={16} />}
                    c={"dimmed"}
                    onClick={() => {
                      copy(item.url);
                      notifications.show({
                        id: "share",
                        title: "Copied moodboard link to clipboard",
                        message: "",
                        color: "dark",
                        icon: <IconCheck size={14} />,
                      });
                    }}
                  >
                    <Text variant="label-md">Copy url</Text>
                  </Button>
                </Vertical>
              </Horizontal>
            ))}
          </Vertical>
        </ScrollArea>
      )}
    </Vertical>
  );
}
